/**
 * @module Basic
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getQueryParam } from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { EditableArea } from '@magnolia/react-editor';
import LCDevBar from '../components/LCDevBar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Modal from '../components/Modal/Modal';
import useModals from '../hooks/useModals';
import fetchBasicTemplate from '../helpers/dataFetchers/basicTemplateFetcher';
import {
  ACTIONS,
  CONSENT_MANAGER_THIRD_PARTY_SERVICES,
  ENDPOINT_WORKSPACE_MAP,
  EVENTS,
  MGNL_ENV_VARS,
} from '../helpers/constants';
import { injectScript } from '../helpers/scriptHelper';

const Basic = (props) => {
  const { modalTriggers, modalVisibilities, showModal } = useModals();
  const [modal, setModal] = React.useState(null);
  const [data, setData] = React.useState(null);
  const { main, displayNavBar = false } = props;
  const navBar = data?.navBar;

  /**
   * Fetch user from local storage to pass along to Segment for analytics.
   */
  let user;
  try {
    user = window?.localStorage?.getItem('user_profile')
      ? JSON.parse(window?.localStorage?.getItem('user_profile'))
      : null;
  } catch (error) {
    Log.error(error);
  }

  React.useEffect(() => {
    const fetchModalByHash = async () => {
      let { hash } = window.location;
      if (hash) {
        // eslint-disable-next-line prefer-destructuring
        hash = hash.split('#')[1];
        const response = await getMagnoliaItem({
          caller: 'src/pages/Basic.js > fetchModalByHash',
          forceCall: true,
          mgnlEnvVars: MGNL_ENV_VARS,
          path: encodeURI(`/.rest/delivery/modal?@name=${hash}`),
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }); // NOSONAR
        if (response?.results?.length) {
          setModal(response.results[0]);
        }
      }
    };

    async function fetchData() {
      try {
        const theme = getQueryParam('theme');
        const basicTemplateData = await fetchBasicTemplate(props, theme);
        setData(basicTemplateData);
      } catch (error) {
        Log.error(error);
      }
    }
    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }

    fetchModalByHash(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience function to trigger analytics for fullscreen change event.
   *
   * @param {object} properties - Data object of properties to use for Segment track call.
   */
  /* istanbul ignore next */
  function callAnalytics(properties) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: properties?.component || 'Video Player',
        episode_id: properties?.episode_id,
        label: `${properties?.fullscreen ? 'Enter' : 'Exit'} Fullscreen`,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        resource_name:
          properties?.resource_name ?? document?.title?.split('|')[0] ?? '',
        series_id: null,
        session_id: null,
        title: document?.title || '',
        type: 'Video',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        video_url: properties?.video_url,
        youtube_id: properties?.youtube_id,
      },
    });
  }

  /**
   * Handler function for fullscreen change event.
   */
  /* istanbul ignore next */
  function handleFullScreenChange(event) {
    const srcElement = event?.srcElement;
    if (srcElement) {
      /**
       * Note: A few Bugsnag errors came through for 'l.closest' is undefined.
       * To alleviate this, adding a check to see if `.closest` is defined first
       * and then setting `eventSrcWrapperElement` as needed. This *shouldn't*
       * even be necessary with optional chaining, but is a failsafe to avoid
       * unhandled bugs and errors.
       */
      let eventSrcWrapperElement = srcElement;
      let componentName = 'Video Player';
      if (
        !srcElement?.classList?.contains('life-church-bitmovin-player') &&
        srcElement?.closest
      ) {
        eventSrcWrapperElement = srcElement?.closest('.video-wrapper');
        componentName = eventSrcWrapperElement?.getAttribute(
          'data-media-component',
        );
      } else if (
        srcElement?.classList?.contains('life-church-bitmovin-player')
      ) {
        componentName = 'Bitmovin Player';
      }
      const eventProperties = {
        component: componentName,
        episode_id: eventSrcWrapperElement?.getAttribute('data-media-id'),
        fullscreen: false,
        resource_name:
          eventSrcWrapperElement?.getAttribute('data-media-title') ??
          document?.title?.split('|')[0].trim() ??
          '',
        video_url: eventSrcWrapperElement?.getAttribute('data-media-url'),
        youtube_id: eventSrcWrapperElement?.getAttribute(
          'data-media-youtube-id',
        ),
      };

      // If there is a fullscreen element, overwrite fullscreen attr to be true.
      if (document.fullscreenElement) {
        eventProperties.fullscreen = true;
      }
      callAnalytics(eventProperties);
    }
  }

  /**
   * Single-run convenience effect to add fullscreen change listener on the document.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if ('onfullscreenchange' in document) {
      document.addEventListener('fullscreenchange', handleFullScreenChange);
      document.addEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange,
      );
      return () => {
        document.removeEventListener(
          'fullscreenchange',
          handleFullScreenChange,
        );
        document.removeEventListener(
          'webkitfullscreenchange',
          handleFullScreenChange,
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to inject third party scripts.
   */
  React.useEffect(() => {
    const { markups } = CONSENT_MANAGER_THIRD_PARTY_SERVICES.google;
    injectScript(markups.googleOptimizeScript, true);
    injectScript(markups.leadershipPodcastAdTracking, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run convenience effect to check for window.location.hash and scroll
   * the element (via its `id`, per browser standard) into view when present.
   * The effect includes a window load listener, within which is a near-instant
   * setTimeout() that ensures rendering is complete before attempting to scroll
   * any content into view.
   */
  React.useEffect(() => {
    function onWindowLoad() {
      window.removeEventListener('load', onWindowLoad);
      if (window?.location?.hash) {
        const element = document.querySelector(window.location.hash);
        if (element && parseInt(element.offsetTop, 10) > 0) {
          setTimeout(() => {
            window.scrollTo({
              behavior: 'instant',
              top: element.offsetTop,
            });
          }, 10);
        }
      }
    }
    window.addEventListener('load', onWindowLoad);
    return () => window.removeEventListener('load', onWindowLoad);
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      {
        <div className="Basic">
          {data?.menuData ? (
            <Header
              menuData={data.menuData}
              menuItems={data.menuItems}
              navItems={data.navItems}
            />
          ) : null}
          <main>
            {displayNavBar.toString() === 'true' ? (
              <NavigationBar {...props} {...{ navBar }} />
            ) : null}
            {main ? <EditableArea className="Area" content={main} /> : null}
            {modal ? (
              <Modal
                data={modal}
                setShowStatus={() => setModal(null)}
                showStatus={true}
              />
            ) : null}
            {modalTriggers
              ? Object.entries(modalTriggers).map(
                  ([modalTriggerId, modalTriggerData]) => {
                    return (
                      <Modal
                        data={modalTriggerData}
                        key={modalTriggerId}
                        setShowStatus={() => {
                          showModal(modalTriggerId, false);
                          window.history.pushState(
                            {},
                            null,
                            window.location.href.split('#')[0],
                          );
                        }}
                        showStatus={modalVisibilities[modalTriggerId]}
                      />
                    );
                  },
                )
              : null}
          </main>
          {data?.footerData ? <Footer footerData={data.footerData} /> : null}
          {process.env.NODE_ENV !== 'production' ? <LCDevBar /> : null}
        </div>
      }
    </>
  );
};

export default Basic;
