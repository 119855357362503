/**
 * @module scriptHelper
 */
import _ from 'lodash';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';

/**
 * Convenience function to inject a `script` tag into the page head or body, based on the supplied data.
 *
 * @param {object} data - Data object containing `script` tag information.
 * @param {boolean} inHead - Boolean flag denoting whether to add the script into the head (true) or body (false).
 */
export function injectScript(data, inHead = true) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  if (data?.src) {
    newScript.src = data.src;
  }
  newScript.async = data?.async ?? null;
  newScript.defer = data?.defer ?? null;
  /**
   * Note: No need to add test coverage for data-testid usage, as its entire
   * intent is to add the ability for targeting the script tag via tests.
   */
  /* istanbul ignore next */
  if (data?.testId) {
    newScript.setAttribute('data-testid', data.testId);
  }
  // Depending on browser and its age, using try/catch to ensure script gets content via .appendChild() or .text.
  if (data?.content) {
    try {
      newScript.appendChild(document.createTextNode(data?.content));
    } catch (error) {
      // No need to cover test coverage for backup older browser case.
      /* istanbul ignore next */
      newScript.text = data?.content;
    }
  }
  if (inHead) {
    document.head.appendChild(newScript);
  } else {
    document.body.appendChild(newScript);
  }
}

/**
 * Convenience function to traverse the supplied HTML string content and add implementUtmParams() to anchor tag href attributes, and add additional onClick attribute for Segment tracking.
 *
 * @param {string} content - HTML markup data as a string that is inspected and adjusted with anchor tag href and onclick attribute updates.
 * @param {string} component - The component name to which to attribute in Segment analytics call.
 *
 * @returns {string} The supplied content string with adjusted anchor tag attributes.
 */
export function implementAnchorTagAttributes(content, component) {
  const div = document.createElement('div');
  div.innerHTML = _.replace(content, /~\//g, '/');
  if (div.innerHTML.toString() !== '') {
    const anchorTags = Array.from(div.getElementsByTagName('a'));
    anchorTags.forEach((a) => {
      if (a.href) {
        a.setAttribute(
          'href',
          implementUtmParams(a.getAttribute('href'), window?.location),
        );
      }
      /**
       * Important: Only add the onclick handler if one doesn't already exist, as
       * there are some components that piggyback on another which uses RichText,
       * for instance, and the onclick handler would be overridden every time
       * which could cause undesirable component name values due to overrides.
       */
      if (!a.getAttribute('onclick')) {
        a.setAttribute(
          'onclick',
          `window.handleAnchorElementClick(event, { component: '${component}' });`,
        );
      }
    });
  }
  return div.innerHTML.toString();
}
