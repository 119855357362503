/**
 * @module CopyThisText
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { filterAttributesForElement } from '@lifechurch/web-tools-io/dist/utils/helpers/attributes';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { isColorSettingValidClassName } from '../../helpers/classNames';
import { implementAnchorTagAttributes } from '../../helpers/scriptHelper';
import ButtonItem from '../ButtonItem/ButtonItem';
import './CopyThisText.scss';

/**
 * Simple component consisting of text in a container along with a button to copy the text to the device clipboard.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.buttonLabel] - The string value of the "copy" button label (Default: "Copy This Text").
 * @param {string} props.content - The content of the text section which is copied to the clipboard on button click.
 * @param {boolean} [props.includeIcon] - Boolean flag denoting whether or not to include the copy icon on the button element.
 * @param {boolean} [props.isDarkMode] - Boolean flag denoting whether or not to use dark mode styles on the component.
 * @param {string} [props.sbOnMobile] - Class name to apply to component wrapper for mobile screen sizes.
 * @param {string} [props.sbOnTabletAndUp] - Class name to apply to component wrapper for tablet and up screen sizes.
 * @param {string} [props.sectionId] - Optional unique id value to assign to the section.
 *
 * @returns {React.ReactElement} The CopyThisText component.
 */
function CopyThisText({
  alignment = '',
  buttonLabel = 'Copy This Text',
  colorsetting,
  content,
  includeIcon = true,
  isDarkMode,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  ...passThroughProps
}) {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [markup, setMarkup] = React.useState('');
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );
  const [isInTextCopiedConfirmation, setIsInTextCopiedConfirmation] =
    React.useState(false);
  const buttonLabelCopied = 'Text Copied!';
  const textCopyTargetRef = React.useRef(null);
  const containerClass = `copy-this-text-container ${
    isDarkMode ? 'dark-mode' : 'light-mode'
  } ${colorSettingClass} ${convertValueToClassName(alignment)} ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();
  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'div',
  });

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function callAnalytics(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Copy This Text',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: buttonLabel,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for Copy button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  async function handleButtonClick(event) {
    callAnalytics(event);
    try {
      /* istanbul ignore next */
      await navigator.clipboard.writeText(
        textCopyTargetRef?.current?.textContent,
      );
      setIsInTextCopiedConfirmation(true);
      /* istanbul ignore next */
      setTimeout(() => {
        setIsInTextCopiedConfirmation(false);
      }, 5000);
    } catch (err) {
      /* istanbul ignore next */
      Log.error('Failed to copy text: ', err);
    }
  }

  /**
   * Convenience effect to convert content to implement anchor tag attributes on
   * content set to be injected via dangerouslySetInnerHTML.
   */
  React.useEffect(() => {
    setMarkup(implementAnchorTagAttributes(content, 'CopyThisText'));
  }, [content]);

  return (
    <div
      {...filteredProps}
      className={containerClass}
      data-testid="lc-copy-this-text-text-container"
      id={sectionId}
    >
      <div
        className="copy-this-text"
        dangerouslySetInnerHTML={{ __html: markup }}
        ref={textCopyTargetRef}
      ></div>
      <ButtonItem
        buttonSize="small"
        className={`${isInTextCopiedConfirmation ? 'copied' : ''}`}
        darkVariant={!isDarkMode}
        icon={
          includeIcon && !isInTextCopiedConfirmation
            ? {
                externalIcon: 'icon-copy',
                field: 'external-icons',
              }
            : null
        }
        iconPosition={`${isInTextCopiedConfirmation ? null : 'icon-to-right'}`}
        id="copy-this-text-copy-button"
        onClick={handleButtonClick}
        style="btn-primary"
        text={isInTextCopiedConfirmation ? buttonLabelCopied : buttonLabel}
      />
    </div>
  );
}

export default CopyThisText;
